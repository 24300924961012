<template>
  <div>
    <div class="mb-8">
      <ClassSearch
        :course="course"
        :from-date="fromDate"
        :list-loading="listLoading"
        :query="query"
        :to-date="toDate"
        @input-change="handleInputChange"
      />
    </div>

    <ClassesList
      :classes-data-list="classesDataList"
      :handle-pagination="handlePagination"
      :handle-sort="handleSort"
      :headers="headers"
      :list-loading="listLoading"
      :page="page"
      :sort="sort"
      :total-entries="totalEntries"
    />
  </div>
</template>

<script>
import ClassSearch from "@/components/dashboard/classes/ClassListSearch.vue"
import urls from "@/utils/urls"
import { getValueOrNull, getSortableFieldsArray } from "@/utils/helpers"
import ClassesList from "@/components/dashboard/classes/ClassesList.vue"

export default {
  name: "UpcomingClasses",

  components: {
    ClassSearch,
    ClassesList,
  },

  data: () => ({
    batchId: null,
    sort: { fields: [], desc: [] },
    fromDate: "",
    toDate: "",
    page: 1,
    listLoading: false,
    query: "",
    course: "",
    batchData: {
      batch: {
        name: "Batch",
      },
    },

    headers: [
      {
        text: "Name",
        value: "name",
      },
      { text: "Course", value: "course", sortable: false },
      { text: "Time", value: "time", sortable: false },
      { text: "", value: "action", sortable: false, align: "end" },
    ],
    classesDataList: {
      data: [],
      total: 0,
    },
  }),

  computed: {
    numberOfPages() {
      return Math.ceil(this.classesDataList.total / 10)
    },

    totalEntries() {
      return `${
        this.page * 10 > this.classesDataList.total
          ? this.classesDataList.total
          : this.page * 10
      } of ${this.classesDataList.total} Entries`
    },
  },

  beforeMount: function() {
    this.batchId = this.$route.params.batchId
  },

  methods: {
    //handle Sort
    handleSort({ sortBy, sortDesc }) {
      this.resetPageNo()
      this.sort = {
        fields: sortBy,
        desc: sortDesc,
      }
      this.getclassesDataList()
    },

    handleInputChange(key, value) {
      this[key] = value
      key !== "page" && this.resetPageNo()
      this.getclassesDataList()
    },

    resetPageNo() {
      this.page = 1
    },

    async getclassesDataList() {
      this.listLoading = true
      try {
        const res = await this.$http.get(
          urls.classes.getClasses(this.batchId),
          {
            params: {
              status: "upcoming",
              page: this.page,
              query: getValueOrNull(this.query),
              course: getValueOrNull(this.course),
              start_date: getValueOrNull(
                this.fromDate !== ""
                  ? this.moment(this.fromDate).format("DD-MM-yyyy")
                  : ""
              ),
              end_date: getValueOrNull(
                this.toDate !== ""
                  ? this.moment(this.toDate).format("DD-MM-yyyy")
                  : ""
              ),
              sort: getSortableFieldsArray(this.sort.fields, this.sort.desc),
            },
          }
        )
        this.classesDataList = res.data.data
      } catch (error) {
        console.error(error)
      } finally {
        this.listLoading = false
      }
    },
    handlePagination(newPage) {
      this.page = newPage
      this.getclassesDataList()
    },
  },
}
</script>

<style lang="scss" scoped></style>
